body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #ffff;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
	font-size: x-large;
	color: #004964;
	border-width: 5px;
	border-color: #c4d8e1;
	margin-left: 5px;
	background: #000;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.main-content {
	padding-top: 60px;
	transition: margin-left 0.5s !important;
	height: calc(100vh - 60px) !important;
}

.menu-row-item {
	transition: margin-left 1.5s !important;
}

.menu-row-title {
	transition: margin-left 0.8s !important;
}

.main-content-segment {
	height: calc(100vh - 60px) !important;
}

.Circle_Icon {
	cursor: pointer !important;
	background-color: whitesmoke !important;
	color: #1f4670 !important;
}

.InputField {
	width: 100%;
	border: none;
	padding: 0 1rem;
}

.InputField:focus {
	outline: none;
}

div[tabindex='-1']:focus {
	outline: 0;
}

/* Header */

.Sidebar {
	display: flex;
	justify-content: space-between;
	/* border-bottom: 1px solid red; */
	box-shadow: 1px 7px 11px -4px rgba(221, 221, 221, 0.76);
}

.Sidebar h1 {
	margin: 1rem;
}

.SidebarNavBar {
	margin: 1rem;
	display: flex;
	list-style-type: none;
}

.SidebarNavBar li {
	padding: 0.5rem 1rem;
}

.breadcrumTitle {
	margin: 1rem !important;
	font-size: 1.3rem !important;
}

.search .results {
	overflow-y: auto;
	max-height: 300px;
}

.PageContainer::-webkit-scrollbar {
	width: 0px;
	height: 10px;
}

/* Track */

.PageContainer::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */

.PageContainer::-webkit-scrollbar-thumb {
	background: #c1c1c1;
	border-radius: 10px;
}

/* Handle on hover */

.PageContainer::-webkit-scrollbar-thumb:hover {
	background: #9e9e9e;
}

.PageContainer {
	margin: 0.5rem 1.5rem;
	margin-top: 1vh;
}

.login-background {
	background: linear-gradient(-45deg, #1f4670, #c4d8e1, #539abd, #1f4670);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

.main-icon-background {
	animation: animation-button 15s ease infinite;
}

.main-text-background {
	animation: animation-button 15s ease infinite reverse;
}

@keyframes gradient {
	0% {
		back: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-webkit-keyframes animation-button {
	0% {
		color: #1f4670;
	}
	25% {
		color: #004964;
	}
	50% {
		color: #c4d8e1;
	}
	75% {
		color: #539abd;
	}
	100% {
		color: #69839f;
	}
}

@keyframes animation-button {
	0% {
		color: #1f4670;
	}
	25% {
		color: #004964;
	}
	50% {
		color: #c4d8e1;
	}
	75% {
		color: #539abd;
	}
	100% {
		color: #69839f;
	}
}

.dashboard-image-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80vh;
}

.dashboard-image-container img {
	margin-bottom: 50px;
}

.dashboard-image-container button {
	padding: 15px;
	background: #1f4670;
	color: #fff;
	font-weight: 700;
	border: none;
	border-radius: 5px;
	box-shadow: 4px 14px 14px -6px #00221b82;
	cursor: pointer;
}

/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width: 320px) {
	.dashboard-image-container img {
		width: 200;
		height: 200;
	}

	.dashboard-image-container button {
		width: 195px;
	}
}

@media (min-width: 320px) {
	.units-list-individual-page-report-container {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding-bottom: 30px;
		margin-top: 10px;
	}
}

/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width: 481px) {
	.dashboard-image-container img {
		width: 280px;
		height: 280px;
	}

	.dashboard-image-container button {
		width: 180px;
		font-size: 15px;
	}
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media (min-width: 641px) {
	.dashboard-image-container img {
		display: block;
		width: 350px;
		height: 350px;
	}

	.dashboard-image-container button {
		width: 300px;
		font-size: 20px;
	}

	.units-list-individual-page-report-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {
	.dashboard-image-container img {
		display: block;
		width: 350px;
		height: 350px;
	}

	.dashboard-image-container button {
		width: 300px;
		font-size: 20px;
	}
}

.format-sections-names .ui.attached.tabular.menu {
	display: flex;
	flex-wrap: wrap;
}

div.tab_menu_container .ui.attached.tabular.menu {
	display: flex;
	flex-wrap: wrap;
}

/* ========================================================== */
.form_module_input{
	width: 100%;
}

/* Estilos para tabletas (pantallas desde 768px hasta 1023px) */

@media (min-width: 768px) and (max-width: 1023px) {
    .form_module_input{
		max-width: 600px;
	}
}

/* Estilos para pantallas de escritorio pequeñas (desde 1024px hasta 1439px) */

@media (min-width: 1024px) and (max-width: 1439px) {
	.form_module_input{
		max-width: 600px;
	}
}

/* Estilos para pantallas de escritorio grandes (1440px y más) */

@media (min-width: 1440px) {
	.form_module_input{
		max-width: 600px;
	}
}
/* ========================================================== */


th.custom_header_cell_styles {
	min-width: 300px !important;
	max-width: 300px !important;
	overflow-x: auto;
	box-sizing: border-box;
	white-space: nowrap;
}