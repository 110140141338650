.SideBar__Outline {
	color: #f1ffff !important;
	margin-bottom: 0;
}

.sidebar {
	height: 100% !important;
	width: 0; /* 0 width - change this with JavaScript */
	position: fixed; /* Stay in place */
	z-index: 0; /* Stay on top */
	bottom: 0;
	left: 0;
   padding-top: 60px;
	background-color: #1F4670; /* Black*/
	overflow-x: auto; /* Disable horizontal scroll */
	transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

.SubMenu__Header {
   background-color: #1F4670 !important;
   border: 0 !important;
   padding: 0 !important;
}

.SubMenu__Item {
	background-color: #1F4670 !important;
	-webkit-transition: all 0.2s ease-in-out !important;
	-moz-transition: all 0.2s ease-in-out !important;
	-o-transition: all 0.2s ease-in-out !important;
	transition: all 0.2s ease-in-out !important;
	&:hover {
		transform: scale(0.95) !important;
		-webkit-transform: scale(0.95) !important;
		-moz-transform: scale(0.95) !important;
		-o-transform: scale(0.95) !important;
	}
}

.SubMenu__Content {
   -webkit-transition: all 0.3s ease-in-out !important;
	-moz-transition: all 0.3s ease-in-out !important;
	-o-transition: all 0.3s ease-in-out !important;
	transition: all 0.3s ease-in-out !important;
}
