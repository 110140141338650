// 1 rem = 14px
$primary_font: "Roboto", Helvetica, Arial, sans-serif;
$secondary_font: 'Open Sans', monospace;
$third_font: 'Lobster', cursive; 
$semantic_font: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;

$color_blue: #1F4670;
$color_blue_alt: #2d5590;
$color_white: #FFFFFF;
$color_white_blue: #9FA9BC;
$color_dark_blue: #15263B;
$color_lg_gray: #707070;
$color_border: #D6DCE1;
$color_empty_state: #a6a6a6;

$size_header: 3.5rem;
$size_sidebar_open: 16.48rem;
$size_sidebar_close: 3.76rem;

$size_margin: 1rem;

$breakpoint-laptop: 1440px;
$breakpoint-laptop-sm: 1240px;
$breakpoint-tablet: 768px;
$breakpoint-tablet-sm: 600px;
$breakpoint-mobile: 500px;
// $breakpoint-mobile: 425px;
// $breakpoint-mobile-sm: 375px;
// $breakpoint-mobile-esm: 320px;